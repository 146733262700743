<template>
  <div>
    <v-scale-transition origin="center center">
      <ConcentricCircles
        v-show="markerVisible"
        class="hotspot-circle"
        :style="markerPosition"
      />
    </v-scale-transition>

    <v-btn
      v-if="isMarkerFromTour == true"
      :color="colour"
      class="map-marker details-button white--text"
      :style="markerPosition"
      :aria-label="ariaLabel"
      @mouseenter="showCircle = false"
      @mouseleave="showCircle = true"
      @focus="mapHotspotFocus()"
            @click="$emit('setIndex')"

    >
      <v-icon v-if="icon !== null">{{ icon }}</v-icon>
      <span class="d-inline-block text-center">{{ title }}</span>
    </v-btn>
    
    <v-btn
      v-else
      :color="colour"
      class="map-marker details-button white--text"
      :style="markerPosition"
      :aria-label="ariaLabel"
      :data-testid="'hotspot-button'+id"
      @mouseenter="showCircle = false"
      @mouseleave="showCircle = true"
      @focus="mapHotspotFocus()"
    >
      <v-icon v-if="icon !== null">{{ icon }}</v-icon>
      <span class="d-inline-block text-center">{{ title }}</span>
    </v-btn>
  </div>
</template>

<script>
import { loadModules } from "esri-loader";
import ConcentricCircles from "../panorama/ConcentricCircles.vue";

export default {
  name: "MapMarkerArcHtml",
  props: [
    "id",
    "coordinates",
    "colour",
    "icon",
    "mapCenter",
    "mapArc",
    "title",
    "arialabel",
    "markerVisible",
    "markerType",
    "currZoomLevel",
    "zoomMarkerFlag",
    "isMarkerFromTour"
  ],
  data: () => ({
    screenXY: undefined,
    currentFocusIndex: 0,
    showCircle: true,
    markerVisibility: true,
  }),

  components: { ConcentricCircles },

  mounted() {
    this.markerObject = undefined;
    this.handler = undefined;
    this.point = undefined;
    console.log(this.mapArc, "ArcMarker coords");
    // let self = this;

    // const focusableElement = ".map-marker";
    // let currentElements = focusableElement;
    // let firstFocusableElement = document.querySelectorAll(currentElements)[0];
    // let focusableContent = document.querySelectorAll(currentElements);
    // let lastFocusableElement = focusableContent[focusableContent.length - 1];

    // document.addEventListener("keydown", function (event) {
    //   let isTabPressed = event.key === "Tab";
    //   if (!isTabPressed) {
    //     if (event.key === "ArrowDown") {
    //       firstFocusableElement.focus();
    //       self.nextFocus();
    //     }
    //     if (event.key === "ArrowUp") {
    //       self.prevFocus(focusableContent);
    //     } reached to last focusable element then focus first focusable element after pressing tab
    //         firstFocusableElement.focus(); // add focus for the first focusable element
    //         event.preventDefault();
    //       }
    //     }
    //   }
    //   } else {
    //     console.log(event.key);
    //     if (document.activeElement === lastFocusableElement) {
    //       console.log(lastFocusableElement, "last focus light");
    //       firstFocusableElement.focus();
    //       event.preventDefault();
    //     }
    //   }

    //   if (event.shiftKey) {
    //     if (document.activeElement === firstFocusableElement) {
    //       lastFocusableElement.focus(); // add focus for the last focusable element
    //       event.preventDefault();
    //     } else {
    //       if (document.activeElement === lastFocusableElement) {
    //         // if focused has
    // });
  },
  created() {
    const self = this;

    loadModules(["esri/geometry/Point"], {
      css: true,
    }).then(([Point]) => {
      self.point = new Point({
        longitude: self.coordinates[0],
        latitude: self.coordinates[1],
      });
    });
  },
  beforeDestroy() {
    if (this.handler !== undefined) this.handler.remove();

    if (this.markerObject !== undefined)
      this.markerLayer.remove(this.markerObject);
  },
  computed: {
    markerPosition() {
      if (this.screenXY === undefined) {
        console.log("No XY");
        return "display:none";
      }

      let screenY = this.screenXY.y - 18;
      let screenX = this.screenXY.x;
      console.log("XY Found: " + screenX + "x " + screenY + "y");
      return (
        "z-index:1; display:inline-block; top: " +
        screenY +
        "px; left: " +
        screenX +
        "px;"
      );
    },
    ariaLabel() {
      return this.arialabel;
    },
  },
  watch: {
    mapCenter: {
      handler(newVal, oldVal) {
        console.log("Map Center Watcher Triggered");
        this.updatedScreenXY();
      },
      deep: true,
    },
    currZoomLevel: function (val) {
      console.log(val, "curr zoom level from arc");
      this.setVisibility();
    },
  },
  methods: {
    updatedScreenXY: function () {
      if (
        this.point !== undefined &&
        this.mapArc !== undefined &&
        this.mapArc.view !== undefined
      ) {
        this.screenXY = this.mapArc.view.toScreen(this.point);
        console.log("Updated XY");
      } else {
        console.log("Failed to update XY");
      }
    },
    testFunc(){
      console.log("test func from mapArc")
    },
    nextFocus() {
      const elements = document.querySelectorAll(".map-marker");
      this.currentFocusIndex++;
      this.currentFocusIndex = this.currentFocusIndex % elements.length;
      elements[this.currentFocusIndex].focus();
    },
    prevFocus() {
      const elements = document.querySelectorAll(".map-marker");
      this.currentFocusIndex--;
      if (this.currentFocusIndex < 0)
        this.currentFocusIndex = elements.length - 1;
      this.currentFocusIndex = this.currentFocusIndex % elements.length;
      elements[this.currentFocusIndex].focus();
    },
    mapHotspotFocus() {
      this.$emit("focused");
    },
  },
};
</script>

<style scoped>
.map-marker {
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: all;
}

.map-marker:focus-visible {
  outline: 4px dashed darkorange;
}

/* Custom CSS for Marker Animations */

.details-button {
  border-radius: 25px;
  background: none;
  transition: width 0.5s ease;
  overflow: hidden;
  transform: translate(-50%, 0); /*Add this*/
  left: 50%; /*Add this*/
  z-index: 0.5;
  min-width: 36px !important;
  padding: 0px !important;
}

.details-button span {
  max-width: 0;
  display: inline-flex;
  white-space: nowrap;
  transition: all 0.5s ease; /* Transition width and padding to avoid "popping" */
  overflow: hidden;
  padding: 0px;
}

.details-button i {
  transition: all 0.5s ease;
}

.details-button:hover span,
.details-button:focus span {
  max-width: 1000px; /* how far the text can expand, adjust based on content */
  padding-left: 8px !important; /* Adds spacing on the left of the text when expanded */
  padding-right: 8px !important; /* Adds spacing on the right of the text when expanded */
}

.details-button:hover i,
.details-button:focus i {
  padding-left: 8px !important; /* Adds spacing on the left of the icon when expanded */
}

.details-button:hover,
.details-button:focus {
  /* width: 300px; */
  z-index: 1;
}

/* .details-button:focus span {
  opacity: 1;
  transition-delay: 0.3s;
  transition: all ease 0.3s;
} */

.hotspot-circle {
  position: absolute;
  transform: scale(1);
  padding-top: 18px;
  top: 0px;
  left: 0px;
}
</style>